<script setup lang="ts">
import type { UseSeoMetaInput } from '@unhead/schema';

const route = useRoute();
const story = await useEsStoryblokStory(articleRelations);

if (!story) {
    throw createError({
        status: 404,
    });
}

const isPreview = route.query?.preview === 'true';

const unresolvedLinks = resolveSecondLevelLinksFindLinks(story.value as unknown as StoryblokStoryInterface);
const { data: sllData } = await useAsyncData(
    `${story.value?.full_slug}/secondLevelData`,
    async () => await resolveSecondLevelLinksGetData(unresolvedLinks, isPreview),
);
if (sllData.value) {
    resolveSecondLevelLinksPatchInData(sllData.value, unresolvedLinks);
}

const config = useRuntimeConfig();

const { data: seoData } = await useAsyncData(
    'cdn/datasource_entries/seo-data',
    async () => await useEsStoryblokDatasource('seo-data'),
);
const { data: globalArticleCopy } = await useAsyncData(
    'cdn/datasource_entries/global-article-copy',
    async () => await useEsStoryblokDatasource('global-article-copy'),
);

const component = story.value?.content?.component ?? '';
if (!articleComponents.includes(component)) {
    throw createError({ statusCode: 404, message: 'Page not found' });
}

const breadcrumbLinks: Ref<BreadcrumbLink[]> = ref([]);
if (
    story.value?.content?.articleBreadcrumbList?.[0]?.breadcrumbList?.length ||
    story.value?.content?.breadcrumbList?.[0]?.breadcrumbList?.length
) {
    const breadcrumbList =
        (story.value?.content?.articleBreadcrumbList?.[0]?.breadcrumbList as ArticleBreadcrumbBlokInterface[]) ||
        (story.value?.content?.breadcrumbList?.[0]?.breadcrumbList as ArticleBreadcrumbBlokInterface[]);
    breadcrumbLinks.value = breadcrumbList.map((crumb) => ({
        text: crumb.name,
        active: false,
        ...useLinkAttrFromStoryblokLink(crumb.url, config.public.ES_DOMAIN),
    }));
} else {
    const breadcrumbsResp = await useAsyncData(`${story.value?.full_slug}/breadcrumbLinks`, async () =>
        useArticleBreadcrumbList(story.value?.full_slug, config.public.ES_DOMAIN),
    );
    if (breadcrumbsResp.data.value) {
        breadcrumbLinks.value = breadcrumbsResp.data.value;
    }
}

const graph = generateArticleJsonLd(
    {
        story: story.value as unknown as StoryblokStoryInterface,
        seoData: seoData.value,
        breadcrumbLinks: breadcrumbLinks.value,
        path: route.path,
        articleType: 'Article',
    },
    config.public.ES_DOMAIN,
);

const metaInfo = generateMetaInfo(
    story.value as unknown as StoryblokStoryInterface,
    route.path,
    config.public.ES_DOMAIN,
);
useSeoMeta(metaInfo as unknown as UseSeoMetaInput);

const canonicalUrl = `${metaInfo.link?.find((link) => link.rel === 'canonical')?.href}`;

interface HeadTagsInterface {
    script: { type: string; innerHTML: string }[];
    link: {
        rel: string;
        href: string;
        type?: string;
    }[];
}

const headTags: HeadTagsInterface = {
    script: [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(graph),
        },
    ],
    link: [
        {
            rel: 'canonical',
            href: canonicalUrl,
        },
    ],
};

if (story.value.full_slug.startsWith('news/')) {
    headTags.link.push({
        rel: 'alternate',
        type: 'application/rss+xml',
        href: 'https://www.energysage.com/rss/news.xml',
    });
}

useHead(headTags);

const h2List = findComponentsInPage(story.value as unknown as StoryblokStoryInterface, 'ArticleH2');
</script>

<template>
    <storyblok-component
        v-if="story"
        :blok="story.content"
        :first-published-at="story.first_published_at || ''"
        :published-at="story.published_at || ''"
        :seo-data="seoData"
        :breadcrumb-links="breadcrumbLinks"
        :h2-list="h2List"
        :global-article-copy="globalArticleCopy" />
</template>
