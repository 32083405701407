import type { MetaInfo, MetaPropertyName, MetaPropertyProperty } from 'vue-meta';
import type { WithContext, Graph, Article, Person, BreadcrumbList, ListItem, ImageObject } from 'schema-dts';
import type { ISbStoryData } from '@storyblok/vue';

const COMPANY_NAME = 'EnergySage';
type ARTICLE_TYPE = 'Article' | 'BlogPosting';

function splitList(items: string): string[] | undefined {
    if (items) {
        return items.split(',').map((s) => s.trim());
    }
    return undefined;
}

export function generateFallbackSeoInfoFromStory(
    story: StoryblokStoryInterface,
    path: string,
): FallbackSeoInfoInterface {
    const canonicalUrl = `https://www.energysage.com${path}`;

    if (story?.content?.component === 'ArticlePage001') {
        const storyContent: ArticlePage001 = story.content as unknown as ArticlePage001;

        const articlePrimaryImage = getArticlePrimaryImage(story as ISbStoryData<ArticlePage001>);

        const imageFileName = articlePrimaryImage?.filename;
        const imageDetails: ImageDetails | null = imageFileName ? getStoryblokImageDetails(imageFileName) : null;

        return {
            canonicalUrl: storyContent?.canonicalUrl?.url
                ? processDomainTokensInUrl(storyContent.canonicalUrl.url)
                : canonicalUrl,
            description: storyContent?.articleEeat?.[0]?.lede,
            image: imageFileName || '',
            imageHeight: imageDetails ? `${imageDetails.height}px` : '',
            imageWidth: imageDetails ? `${imageDetails.width}px` : '',
            title: storyContent?.articleEeat?.[0]?.header,
        };
    }

    if (story?.content?.component === 'LocalArticlePage001') {
        const storyContent: LocalArticlePage001 = story.content as unknown as LocalArticlePage001;
        const { og_image } = story?.content?.seo || {};
        const imageDetails: ImageDetails | null = og_image ? getStoryblokImageDetails(og_image) : null;

        return {
            canonicalUrl,
            description: storyContent?.eeat?.[0]?.lede,
            // no fallback image on this page besides OG image (if provided), as the state graphic is
            // often an inconvenient aspect ratio and there's no other image on the main page
            image: og_image || '',
            imageHeight: imageDetails ? `${imageDetails.height}px` : '',
            imageWidth: imageDetails ? `${imageDetails.width}px` : '',
            title: storyContent?.eeat?.[0]?.heading,
        };
    }

    return {
        canonicalUrl,
        description: '',
        image: '',
        imageHeight: '0px',
        imageWidth: '0px',
        title: '',
    };
}

export function generateMetaInfo(story: StoryblokStoryInterface, path: string, domain: string): MetaInfo {
    const {
        title,
        description,
        og_title,
        og_description,
        og_image,
        twitter_title,
        twitter_description,
        twitter_image,
    } = story?.content?.seo || {};
    const fallbackInfo: FallbackSeoInfoInterface = generateFallbackSeoInfoFromStory(story, path);

    const meta: (MetaPropertyName | MetaPropertyProperty)[] = [
        { name: 'description', content: description || fallbackInfo.description },
        // Open Graph tags
        { property: 'og:site_name', content: COMPANY_NAME },
        { property: 'og:url', content: `${domain}${path}` },
        { property: 'og:type', content: 'article' },
        { property: 'og:description', content: og_description || description || fallbackInfo.description },
        { property: 'og:title', content: og_title || title || fallbackInfo.title },
        { property: 'og:image', content: og_image || fallbackInfo.image },
        // Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@energysage' },
        { name: 'twitter:title', content: twitter_title || title || fallbackInfo.title },
        { name: 'twitter:description', content: twitter_description || description || fallbackInfo.description },
        { name: 'twitter:image', content: twitter_image || fallbackInfo.image },
    ];

    const metaLinks: { rel: string; href: string; hreflang?: string }[] = [
        { rel: 'canonical', href: fallbackInfo.canonicalUrl },
    ];

    if (story?.full_slug?.startsWith('news/')) {
        meta.push({ name: 'robots', content: 'max-image-preview:large' });
    }

    if (story?.content?.component && story?.content?.component === 'ArticlePage001') {
        const storyContent: ArticlePage001 = story.content as unknown as ArticlePage001;
        if (storyContent.noIndex) {
            meta.push({ name: 'robots', content: 'noindex', hid: 'robots-meta-tag' });
        }
        if (storyContent.altLanguageMetaTags && storyContent.altLanguageMetaTags.length > 0) {
            storyContent.altLanguageMetaTags.forEach((langTag) => {
                metaLinks.push({
                    rel: 'alternate',
                    hreflang: langTag.altLanguage,
                    href: processDomainTokensInUrl(langTag.altLanguageArticleUrl.url),
                });
            });
        }
    }

    return {
        title: title || fallbackInfo.title,
        meta,
        link: metaLinks,
    };
}

function generatePersonJsonLd(
    storyContent: AuthorPageBlokInterface,
    path: string,
    domain: string,
): WithContext<Person> | undefined {
    if (!storyContent) {
        return undefined;
    }
    const authorInfo = storyContent.authorInfo?.[0] || {
        name: '',
        title: '',
        avatar: {
            filename: '',
        },
    };
    const authorConnect = storyContent.authorConnect?.[0] || {
        linkedinLink: {
            url: '',
        },
        twitterLink: {
            url: '',
        },
    };
    const authorSocials = [];
    if (authorConnect.linkedinLink.url) {
        authorSocials.push(authorConnect.linkedinLink.url);
    }
    if (authorConnect.twitterLink.url) {
        authorSocials.push(authorConnect.twitterLink.url);
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'Person',
        name: authorInfo.name,
        image: authorInfo.avatar.filename,
        url: `${domain}${path}`,
        description: storyContent.jsonLdDescription,
        jobTitle: authorInfo.title,
        publishingPrinciples: 'https://www.energysage.com/editorial-guidelines/',
        sameAs: authorSocials.length ? authorSocials : undefined,

        alumniOf: storyContent.authorEducationalOrganizationName
            ? {
                  '@type': 'EducationalOrganization',
                  name: storyContent.authorEducationalOrganizationName,
              }
            : undefined,
    };
}

function buildBreadcrumbJsonList(breadcrumbs: BreadcrumbLink[], domain: string): WithContext<BreadcrumbList> {
    const items: ListItem[] = breadcrumbs.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.text,
        item: item.to ? `${domain}${item.to}` : item.href,
    }));

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items,
    };
}

function buildArticle(
    {
        story,
        seoData,
        breadcrumbLinks,
        path,
        articleType,
    }: {
        story: StoryblokStoryInterface;
        seoData: SeoDataInterface;
        breadcrumbLinks: BreadcrumbLink[];
        path: string;
        articleType: ARTICLE_TYPE;
    },
    domain: string,
): [WithContext<Article>, WithContext<BreadcrumbList>] {
    const {
        supportEmail,
        supportPhoneNumber,
        companyEmail,
        companyLogo,
        companyPhoneNumber,
        sameAsURLs,
        knowsAboutTags,
        areaServed,
    } = seoData || {};
    const { title, description } = story?.content?.seo || {};
    const storyContent = story?.content as unknown as ArticlePage001 | LocalArticlePage001 | undefined;
    const fallbackInfo: FallbackSeoInfoInterface = generateFallbackSeoInfoFromStory(story, path);

    // Find the correct fields for Eeat and breadcrumbs based on the type of ArticlePage this is
    let articleEeat:
        | ArticleEeatBlokInterface[]
        | LocalArticleEeatBlokInterface[]
        | LocalArticleEeatV2BlokInterface[]
        | undefined;
    let updatedDate;
    if (storyContent?.component === 'ArticlePage001') {
        articleEeat = (storyContent as ArticlePage001).articleEeat;
        updatedDate = articleEeat?.[0].legacyUpdatedDate;
    } else if (storyContent?.component === 'LocalArticlePage001') {
        articleEeat = (storyContent as LocalArticlePage001).eeat;
        updatedDate = articleEeat?.[0].lastUpdatedDate;
    }

    const logoDimensions = getStoryblokImageDetails(companyLogo);
    let image: ImageObject | undefined;
    if (fallbackInfo.image) {
        image = {
            '@type': 'ImageObject',
            url: fallbackInfo.image,
            width: fallbackInfo.imageWidth,
            height: fallbackInfo.imageHeight,
        };
    }

    let editedBy: StoryblokStoryInterface<AuthorPageBlokInterface> | undefined;
    const authorSchema: WithContext<Person>[] = [];
    if (articleEeat && articleEeat.length > 0) {
        editedBy = articleEeat[0]?.editedBy;
        const authors = articleEeat[0]?.authors;
        authors.forEach((author) => {
            const authorPath = author.full_slug ? `/${author.full_slug}/` : '';
            const authorJsonLd = generatePersonJsonLd(author.content, authorPath, domain);
            if (authorJsonLd) {
                authorSchema.push(authorJsonLd);
            }
        });
    }

    const updatedModifiedDate = getDatetimeFromStoryblokString(updatedDate);
    let modifiedDate: Date;
    if (updatedModifiedDate !== '') {
        modifiedDate = updatedModifiedDate;
    } else {
        modifiedDate = new Date(story?.published_at as string);
    }

    const article: WithContext<Article> = {
        '@context': 'https://schema.org',
        '@type': articleType,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${domain}${path}`,
        },
        headline: title || fallbackInfo.title,
        description: description || fallbackInfo.description,
        image,
        author: authorSchema,
        editor: editedBy?.content
            ? generatePersonJsonLd(editedBy.content, `/${editedBy.full_slug}/`, domain)
            : undefined,
        publisher: {
            '@type': 'Organization',
            name: COMPANY_NAME,
            url: domain,
            logo: {
                '@type': 'ImageObject',
                url: companyLogo,
                width: `${logoDimensions.width}px`,
                height: `${logoDimensions.height}px`,
            },
            sameAs: splitList(sameAsURLs),
            contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'Customer Service',
                telephone: supportPhoneNumber,
                email: supportEmail,
            },
            email: companyEmail,
            telephone: companyPhoneNumber,
            knowsAbout: splitList(knowsAboutTags),
            areaServed: splitList(areaServed),
            founder: 'Vikram Aggarwal',
            foundingDate: '2011-01-01',
        },
        datePublished: new Date(story?.first_published_at as string).toISOString().slice(0, 10),
        dateModified: modifiedDate.toISOString().slice(0, 10),
    };

    const breadcrumbJsonList = buildBreadcrumbJsonList(breadcrumbLinks, domain);

    return [article, breadcrumbJsonList];
}

export function generateArticleJsonLd(
    {
        story,
        seoData,
        breadcrumbLinks,
        path,
        articleType,
    }: {
        story: StoryblokStoryInterface;
        seoData: SeoDataInterface;
        breadcrumbLinks: BreadcrumbLink[];
        path: string;
        articleType: ARTICLE_TYPE;
    },
    domain: string,
): Graph {
    const article = buildArticle({ story, seoData, breadcrumbLinks, path, articleType }, domain);
    return {
        '@context': 'https://schema.org',
        '@graph': article,
    };
}
